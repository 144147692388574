import React from "react";
import PropTypes from "prop-types";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY, RESPONSIVE_VALUE_NEW } from "../../../prop_types";
import * as classes from "./column.module.scss";

export default function Column({
  children,
  settings,
  span,
  isContentCentered,
}) {
  const [settingsClassNames] = useSettingsClassNames(settings);

  const classNames = [classes.column, ...settingsClassNames];

  if (span) {
    ["XXL", "XL", "L", "M", "S", "XS", "XXS"].forEach((breakpoint) => {
      const value = span[breakpoint];
      if (value) {
        classNames.push(classes[`span_${breakpoint}_${value}`]);
      }
    });
  }

  if (isContentCentered) {
    classNames.push(classes.isContentCentered);
  }

  return <div className={classNames.join(" ")}>{children}</div>;
}

Column.defaultProps = {
  children: null,
  settings: null,
  span: null,
  isContentCentered: false,
};

Column.propTypes = {
  children: PropTypes.oneOfType([BLOK_ARRAY, PropTypes.node]),
  settings: BLOK_ARRAY,
  span: RESPONSIVE_VALUE_NEW,
  isContentCentered: PropTypes.bool,
};
