import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import { BLOK_ARRAY, COLOR } from "../../../prop_types";
import * as classes from "./wave.module.scss";

const svgContext = require.context(
  "!@svgr/webpack?svgo=false!./wave",
  false,
  /\.svg$/
);

export default function Wave({
  children,
  color,
  containerClassNames,
  flip,
  position,
  wave,
  ...rest
}) {
  const classNames = [classes.wave];

  if (flip) {
    classNames.push(classes.isFlip);
  }

  if (position === "top") {
    classNames.push(classes.isTop);
  }
  if (position === "bottom") {
    classNames.push(classes.isBottom);
  }

  const { default: WaveSvg } = svgContext(`./wave_${wave}.svg`) || {};

  // Set color on parent element, as svg path uses `currentColor` as fill,
  // inheriting parent color
  const style = {
    color: color?.color || "white",
  };

  if (!WaveSvg) {
    return null;
  }

  return (
    <SbEditable content={rest}>
      <div className={classnames(classNames.join(" "), containerClassNames)}>
        {position === "top" && (
          <div className={classes.container} style={style}>
            <div className={classes.inner}>
              <WaveSvg preserveAspectRatio="none" />
            </div>
          </div>
        )}
        {children}
        {position === "bottom" && (
          <div className={classes.container} style={style}>
            <div className={classes.inner}>
              <WaveSvg preserveAspectRatio="none" />
            </div>
          </div>
        )}
      </div>
    </SbEditable>
  );
}

Wave.defaultProps = {
  children: null,
  color: null,
  containerClassNames: "",
  flip: false,
  wave: 1,
};

Wave.propTypes = {
  children: BLOK_ARRAY,
  color: PropTypes.oneOfType([PropTypes.string, COLOR]),
  containerClassNames: PropTypes.string,
  flip: PropTypes.bool,
  position: PropTypes.oneOf(["top", "bottom"]).isRequired,
  wave: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
