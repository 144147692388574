import React from "react";
import PropTypes from "prop-types";
import * as classes from "./gradient.module.scss";

export default function Gradient({ gradient }) {
  const gradientClassNames = [classes.gradient, classes[`${gradient}Gradient`]];

  return <div className={gradientClassNames.join(" ")} />;
}

Gradient.propTypes = {
  gradient: PropTypes.oneOf([
    "blue",
    "data_api",
    "default",
    "developers",
    "industries",
    "paydirect",
    "onboarding",
    "payments",
    "people",
    "white",
  ]),
};

Gradient.defaultProps = {
  gradient: "default",
};
