// extracted by mini-css-extract-plugin
export var column = "column-module--column--7cmmb";
export var isContentCentered = "column-module--isContentCentered--dD2-i";
export var span_L_1 = "column-module--span_L_1--PQCyH";
export var span_L_10 = "column-module--span_L_10---qKRU";
export var span_L_11 = "column-module--span_L_11--N1xkT";
export var span_L_12 = "column-module--span_L_12--gnzmw";
export var span_L_2 = "column-module--span_L_2--GNKwn";
export var span_L_3 = "column-module--span_L_3--60SeE";
export var span_L_4 = "column-module--span_L_4--VcBYm";
export var span_L_5 = "column-module--span_L_5--zOTmc";
export var span_L_6 = "column-module--span_L_6--00US4";
export var span_L_7 = "column-module--span_L_7--tYuaz";
export var span_L_8 = "column-module--span_L_8--pB5Vv";
export var span_L_9 = "column-module--span_L_9--X3LGI";
export var span_M_1 = "column-module--span_M_1--aAc-M";
export var span_M_10 = "column-module--span_M_10--SKr9F";
export var span_M_11 = "column-module--span_M_11---fmZc";
export var span_M_12 = "column-module--span_M_12--RPU-z";
export var span_M_2 = "column-module--span_M_2--fNENj";
export var span_M_3 = "column-module--span_M_3--y9oaL";
export var span_M_4 = "column-module--span_M_4--MA8kI";
export var span_M_5 = "column-module--span_M_5--l1DsI";
export var span_M_6 = "column-module--span_M_6--WBMED";
export var span_M_7 = "column-module--span_M_7--YvW7z";
export var span_M_8 = "column-module--span_M_8--j11Ow";
export var span_M_9 = "column-module--span_M_9--8psb8";
export var span_S_1 = "column-module--span_S_1--BE97l";
export var span_S_10 = "column-module--span_S_10--8Bnkg";
export var span_S_11 = "column-module--span_S_11--a8u95";
export var span_S_12 = "column-module--span_S_12--e7JFG";
export var span_S_2 = "column-module--span_S_2--5Sksg";
export var span_S_3 = "column-module--span_S_3--gKCKG";
export var span_S_4 = "column-module--span_S_4--I69aD";
export var span_S_5 = "column-module--span_S_5--UuU5u";
export var span_S_6 = "column-module--span_S_6--zvy9n";
export var span_S_7 = "column-module--span_S_7--rk-wu";
export var span_S_8 = "column-module--span_S_8--RcRlh";
export var span_S_9 = "column-module--span_S_9--d6d83";
export var span_XL_1 = "column-module--span_XL_1--lmavy";
export var span_XL_10 = "column-module--span_XL_10--CcnyJ";
export var span_XL_11 = "column-module--span_XL_11--dJFSM";
export var span_XL_12 = "column-module--span_XL_12--nkGk1";
export var span_XL_2 = "column-module--span_XL_2--wDFAf";
export var span_XL_3 = "column-module--span_XL_3--rDSHU";
export var span_XL_4 = "column-module--span_XL_4--0UWr4";
export var span_XL_5 = "column-module--span_XL_5--UVw6o";
export var span_XL_6 = "column-module--span_XL_6--Z6myi";
export var span_XL_7 = "column-module--span_XL_7--7kNcI";
export var span_XL_8 = "column-module--span_XL_8--hngLF";
export var span_XL_9 = "column-module--span_XL_9--h2rbA";
export var span_XS_1 = "column-module--span_XS_1--MbDV+";
export var span_XS_10 = "column-module--span_XS_10--eb4n4";
export var span_XS_11 = "column-module--span_XS_11--gUFVf";
export var span_XS_12 = "column-module--span_XS_12--VzPd+";
export var span_XS_2 = "column-module--span_XS_2--3m7y1";
export var span_XS_3 = "column-module--span_XS_3--y4W-D";
export var span_XS_4 = "column-module--span_XS_4--8ZCab";
export var span_XS_5 = "column-module--span_XS_5--BkhS6";
export var span_XS_6 = "column-module--span_XS_6--AweV6";
export var span_XS_7 = "column-module--span_XS_7--p+biY";
export var span_XS_8 = "column-module--span_XS_8--W-LEA";
export var span_XS_9 = "column-module--span_XS_9--NiWMG";
export var span_XXL_1 = "column-module--span_XXL_1--beaqK";
export var span_XXL_10 = "column-module--span_XXL_10--j3vws";
export var span_XXL_11 = "column-module--span_XXL_11--RI18Q";
export var span_XXL_12 = "column-module--span_XXL_12--yVsXM";
export var span_XXL_2 = "column-module--span_XXL_2--WwbvC";
export var span_XXL_3 = "column-module--span_XXL_3--fyjX8";
export var span_XXL_4 = "column-module--span_XXL_4--I0GCW";
export var span_XXL_5 = "column-module--span_XXL_5--HhuGl";
export var span_XXL_6 = "column-module--span_XXL_6--oa8zV";
export var span_XXL_7 = "column-module--span_XXL_7--cZFar";
export var span_XXL_8 = "column-module--span_XXL_8--E7g35";
export var span_XXL_9 = "column-module--span_XXL_9--TSM8w";
export var span_XXS_1 = "column-module--span_XXS_1--P6SsZ";
export var span_XXS_10 = "column-module--span_XXS_10--3h7E1";
export var span_XXS_11 = "column-module--span_XXS_11--LnDLh";
export var span_XXS_12 = "column-module--span_XXS_12--USXhS";
export var span_XXS_2 = "column-module--span_XXS_2--JM47u";
export var span_XXS_3 = "column-module--span_XXS_3--N-C0e";
export var span_XXS_4 = "column-module--span_XXS_4--JvOWr";
export var span_XXS_5 = "column-module--span_XXS_5--cOZQp";
export var span_XXS_6 = "column-module--span_XXS_6--XX-Hc";
export var span_XXS_7 = "column-module--span_XXS_7--V6n6h";
export var span_XXS_8 = "column-module--span_XXS_8--rUFNG";
export var span_XXS_9 = "column-module--span_XXS_9--1pyar";