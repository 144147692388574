// extracted by mini-css-extract-plugin
export var blueGradient = "gradient-module--blueGradient--85SPk";
export var data_apiGradient = "gradient-module--data_apiGradient--ZEZlM";
export var defaultGradient = "gradient-module--defaultGradient--CDvg3";
export var developersGradient = "gradient-module--developersGradient--GPaZT";
export var gradient = "gradient-module--gradient--Ty1Q8";
export var industriesGradient = "gradient-module--industriesGradient--yo2la";
export var onboardingGradient = "gradient-module--onboardingGradient--TeGdr";
export var paydirectGradient = "gradient-module--paydirectGradient--pi9ey";
export var payments_apiGradient = "gradient-module--payments_apiGradient--IW1HS";
export var peopleGradient = "gradient-module--peopleGradient--jB7Xz";